import { darken } from "polished";
import styled, { css } from "styled-components";

export const DeliveryOptionsContainer = styled.div`
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
`;
export const DeliveryMethodsContainer = styled.div`
  background-color: white;
  padding: 15px 20px;
`;
export const DeliveryMethods = styled.div`
  display: flex;
  align-items: center;
  background-color: peach;
  color: black;
  border: 1px solid ${(prop) => prop.theme.colors.gray};
  border-radius: 40px;

  ${({ onlyPickUp }) =>
    onlyPickUp &&
    css`
      border: 2px solid ${(prop) => prop.theme.colors.primary};
      color: ${(prop) => prop.theme.colors.primary};
    `}
`;
export const DeliveryMethodItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background-color: white;
  color: ${(onlyPickUp) =>
    onlyPickUp
      ? (prop) => prop.theme.colors.primary
      : (prop) => prop.theme.colors.grayDark};
  border-radius: 40px;
  padding: 5px 20px;

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${(prop) => prop.theme.colors.grayDark};
      color: white;
    `}
`;

export const DeliveryIcon = styled.div`
  font-size: 22px;
  font-weight: bold;
  padding-top: 5px;
`;

export const DeliveryAddressContainer = styled.div`
  padding: 10px;
  background-color: ${(prop) => prop.theme.colors.background};
  display: flex;
  align-items: center;
  font-size: 20px;
`;
export const DeliveryAddress = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;
  width: 100%;
  background-color: ${(prop) => prop.theme.colors.background};
  display: flex;
`;

export const DeliveryAddressNew = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: ${(prop) => darken(0.25, prop.theme.colors.secondary)};
`;
