import styled from "styled-components";
import { CardComponent } from "../../components/Card/style";
import { HeaderComponent } from "../../components/Header/style";

export const HomeContainer = styled.div` 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    justify-content: space-between;
    height: 100%;
    width: 100%;
`

export const HomeHeaderComponent = styled(HeaderComponent)` 
    display: flex;
    justify-content: center;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    width: 100%;
`


export const HeaderSearch = styled.div` 
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 40px;
    z-index: 2;
`

export const StoreCard = styled(CardComponent)` 
    display: flex;
    flex-direction: column;
    z-index: 2;
    max-height: 100px;
`

export const HeaderLogoContainer = styled.div` 
    width: 20vh;
    height: 20vh;
    max-width: 100px;
    max-height: 100px;

    @media (max-width: 768px) {
    width: 15vh;
    height: 15vh;
    }
`

export const HeaderLogo = styled.img` 
    width: 100%;
    height: 100%;
    object-fit: contain;
`

export const CardInfo = styled.div` 
    padding: 8px 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
export const CardBody = styled.div` 
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 200px) {
      grid-template-columns: 1fr 2fr;
    }
`
export const CardButton = styled.div` 
    position: absolute;
    top: 3px;
    width: 100px;
    height: 4px;
    background-color: ${prop => prop.theme.colors.gray};
    align-self: center;

    &:hover{ 
        cursor: pointer;
    }
`

export const CardTitle = styled.div` 
    font-size: 24px;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 18px;
    }
`

export const CardSubtitle = styled.div` 
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 14px;
    }
`
export const CardDelivery = styled.div` 
    font-size: 12px;
`
export const HomePageContainer = styled.div` 
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    width: 80%;

    ::-webkit-scrollbar {
        display: none;
    }
`

export const HomePromos = styled.div` 
    border-radius: 10px;
`

export const HomeCategoriesContainer = styled.div` 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-top: 10px;
`

export const CategoryItemCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
`


export const Overlay = styled.div`
    position: absolute;   
    height: 100%;   
    width: 100%;   
    top: 0;   
    background-color: rgba(0, 0, 0, .5);   
    opacity: 1;  
`

export const CategoryItemTitle = styled.div`
    text-align: center;
    word-break: break-word;
    color: white;
    font-size: 22px;
    font-weight: bold;
    z-index: 2;
    width: 135px;
`
export const PromoText = styled.div`
    position: absolute;
    z-index: 2;
    color: white;
    font-size: 20px;
    font-weight: bold;
    bottom: 60px;
    right: 20px;
    text-align: right;
    max-width: 85%;
`
export const OverlayHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    color: #FFF;
    background: rgba(2, 30, 81, 0.4);
    z-index: 1;
`

