import styled from "styled-components";
import { CardComponent } from "../Card/style";

const ImageCard = styled(CardComponent)` 
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
`
export default ImageCard;