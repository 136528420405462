import styled from "styled-components";
import { darken } from "polished";

export const SubcategoriesBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(60px, auto);
  grid-gap: 10px;
  padding: 15px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Subcategory = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${(prop) => prop.theme.colors.grayBackground};
  border: 1px solid;
  border-color: ${(prop) => darken(0.05, prop.theme.colors.graySuperLight)};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
  border-radius: 4px;
  padding: 10px 20px;
  word-break: break-word;

  &:hover {
    cursor: pointer;
  }
`

