import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getProduct } from "../../services/products/getProduct";
import { ProductDetailPage } from "./ProductDetailPage";
import { useCart } from "../../context/cart";
import { ProductDetailComponent } from "./style";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useRouteNavigation } from "../../hooks/useRouteNavigation";
import { useImages } from "../../context/images";
import Loader from "../../components/Loader/";
import ProductDetailHeader from "./ProductDetailHeader";
import { EditAlert } from '../ProductDetail/AddOrEditAlert/Edit';

export default function ProductDetail() {
  const { contextImageGallery } = useImages();
  const [, setImages] = contextImageGallery;
  const { productId } = useParams();
  const { t } = useTranslation();
  const { addProduct } = useCart();
  const [product, setProduct] = useState({});
  const [enableAdd, setEnableAdd] = useState(true);
  const [loading, setLoading] = useState(true);
  const goToRoute = useRouteNavigation();
  const [show, setShow] = useState(false);
  useEffect(() => {
    getProduct(productId).then((product) => {
      setProduct(product);
      setLoading(false);
    });
  }, [productId]);
  const notifyProductAdded = (name) =>
    toast.success(t("detail.productAdded", { product: name }));
  const notifyError = () => toast.error(t('detail.productAddedError'));
  const addToCart = (groups, comments, inputValue, tableWhoSalePrice, originalPrice) => {
    addProduct({
      id: product._id,
      name: product.name,
      unit: 'gr',
      description: product.descriptionProduct,
      price: tableWhoSalePrice ? tableWhoSalePrice.price : originalPrice,
      purchase: product.purchasePrice,
      quantity: inputValue,
      optionsGroup: groups,
      comments: comments,
    }).then(() => {
      notifyProductAdded(product.name);
      setEnableAdd(false);
      setTimeout(function () {
        setImages({});
        goToRoute('/home');
      }, 1500);
    }).catch(error => {
      console.log(error)
      notifyError();
      setShow(true);
    });
  };
  return (
    <ProductDetailComponent>
      {show === false ?
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <ProductDetailHeader product={product} />
              {Object.keys(product).length > 0 && (
                <ProductDetailPage
                  images={product.imageUrl}
                  name={product.name}
                  description={product.descriptionProduct}
                  price={product.salePrice}
                  weightPerPiece={product.weightPerPiece}
                  stock={product.stock}
                  wholesalePriceStatus={product.wholesalePriceStatus}
                  wholesalePrice={product.wholesalePrice}
                  optionsGroup={product.optionsGroup.filter(
                    (group) => group.opciones.length > 0
                  )}
                  enableButton={enableAdd}
                  buttonTitle={t("detail.addToCart")}
                  onClick={addToCart}
                />
              )}
            </>
          )}
        </>
        :
        <EditAlert show={show} message={t('detail.productAddedError')} />
      }
    </ProductDetailComponent>
  );
}