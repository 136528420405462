import React, { useEffect, useState } from 'react';
import { CartDelivery, CartPaymentContainer, CartSubtotal, CartTotal, CartTotalContainer, CartPaymentCancel } from './style';
import { useTranslation } from "react-i18next";
import { useCart } from '../../../context/cart';
import { useShop } from '../../../context/shop';
import ConfirmDelete from '../ConfirmDelete';
import formatNumber from '../../../helpers/formatNumber';
import { useAddress } from '../../../context/address';
import { useParams } from 'react-router';
import { createQuote } from '../../../services/delivery/createQuote';
import propTypes from 'prop-types';
export default function CartPayment({ showAlert }) {
    const { t } = useTranslation();
    const { storeId } = useParams();
    const [ showCancel, setShowCancel] = useState(false);
    const { shop, deliveryOption } = useShop();
    const [delivery,] = deliveryOption;
    const { deliveryAddress } = useAddress();
    const [address,] = deliveryAddress;
    const { subtotal, resetCart, total, cart, deliveryAmount } = useCart();
    const [, setDeliveryAmount] = deliveryAmount;
    const [quote, setQuote] = useState(null);

    useEffect(() => {
        if (address.lat && address.lng) {
            const orderInfo = {
                campaign: storeId,
                description: cart.products.map((product) => product.name).join(", "),
                clientName: cart.clientId === 'qrcode' ? shop.name : cart.clientName,
                sale: cart.id,
                lat: address.lat,
                long: address.lng,
                address: address.additionalInfo ? `${address.description}. ${address.additionalInfo}` : address.description
            }
            createQuote(orderInfo).then((result) => {
                setQuote(result.deliveryAmount);
                setDeliveryAmount(result.deliveryAmount);
            });
        }
    }, [storeId, shop, cart, address, setDeliveryAmount]);

    return <CartPaymentContainer>
        <CartTotalContainer>
            {delivery && <CartSubtotal>
                <div>
                    {t('cart.subtotal')}
                </div>
                <div>
                    {formatNumber(subtotal)}
                </div>
            </CartSubtotal>}
            {delivery && <CartDelivery>
                <div>
                    {t('cart.delivery')}
                </div>
                <div>
                    {quote ? formatNumber(quote) : '-'}
                </div>
            </CartDelivery>}
            <CartTotal>
                <div>
                    {t('cart.total')}
                </div>
                <div>
                    {formatNumber(total)}
                </div>
            </CartTotal>
        </CartTotalContainer>

        <ConfirmDelete show={showCancel} onClose={() => { setShowCancel(false) }} onConfirm={() => {
            resetCart(); showAlert(true);
        }} />
       
        <CartPaymentCancel onClick={() => { setShowCancel(true) }}
        >
            {t('cart.cancelOrder')}
        </CartPaymentCancel>
    </CartPaymentContainer>;
}


CartPayment.propTypes = {
    showAlert: propTypes.func,
}