import styled from "styled-components";
import { css } from "styled-components";

export const AlertComponent = styled.div` 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: white;
    display: none;
    z-index: 9999;

    ${({ show }) => show && css`
         display: block;
      `
    }
`

export const AlertMain = styled.div` 
    position: fixed;
    opacity: 10;
    top: 35%;
    width: 100%;
    height: 100px;
    left: 50%;
    transform: translate(-50%,-50%);  
`

export const AlertContainer = styled.div` 
    background-color: white;
    display: flex;
    flex-direction: column;
    position: relative;
`
export const AlertBody = styled.div` 
    text-align: center;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
`
export const DoneImage = styled.img`
    height: 100px;
    width: 100px;
    display: block;
    margin:auto;
`

export const AlertTitle = styled.div` 
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: ${prop => prop.theme.colors.primary};
`

export const AlertText = styled.div` 
    font-size: 18px;
    margin-bottom: 10px;
    color: ${prop => prop.theme.colors.grayDark};
`

export const ChannelList = styled.div` 
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 5px;
    font-size: 14px;
    margin-top: 10px;
`