import axios from "axios";

export const getProductsByCategory = async (begin, end, categoryId) => {
    const url = `${process.env.REACT_APP_TEST_RETAIL_URL}/products?end=${end}&begin=${begin}&categoryId=${categoryId}`;
    const resp = await axios.get(url);
    const data = resp.data;

    return data.map((item) => {
        const imageUrl = Array.isArray(item.imageUrl) ? item.imageUrl : [item.imageUrl];
        return {
            ...item,
            imageUrl,
        };
    });
}