import React from 'react';
import propTypes from 'prop-types';
import { AlertBody, AlertComponent, AlertMain, AlertText, AlertTitle, DoneImage} from './style';
import done from '../../../assets/icons/done.svg';

export const Thanks = ({ show, message }) => {

    return <AlertComponent show={show} >
        <AlertMain>
                <AlertBody>
                    <DoneImage src={done}></DoneImage>
                    <AlertTitle>
                        {message}
                    </AlertTitle>
                    <AlertText>
                    </AlertText>
                </AlertBody>
        </AlertMain>
    </AlertComponent>
}

Thanks.propTypes = {
    show: propTypes.bool,
    message: propTypes.string,
}



