import styled from "styled-components";

export const AlertComponent = styled.div` 
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: ${({ show }) => (show ? '1' : '0')};
    transition: opacity 0.3s ease-in-out;
    pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
`;

export const AlertMain = styled.div` 
    background-color: #ffffff;
    width: 300px;
    height: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    border-radius: 10px;
`;

export const DoneImage = styled.img`
    height: 50%;
    width: 50%;
`;

export const AlertBody = styled.div` 
    text-align: center;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
`;

export const AlertTitle = styled.div` 
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: ${prop => prop.theme.colors.primary};
`;

export const AlertText = styled.div` 
    font-size: 18px;
    margin-bottom: 10px;
    color: ${prop => prop.theme.colors.grayDark};
`;
