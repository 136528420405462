import styled, { css } from "styled-components";

export const ArrowLeftButton = styled.button` 
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 1px solid ${prop => prop.theme.colors.graySuperLight};
    border-radius: 50%;
    z-index: 2;
    width: 30px;
    height: 30px;
    
    &:hover{
      cursor: pointer;
    }

    ${({ round }) =>
    !round && css`
        border: none;
      `
  }

  ${({ detail }) =>
    detail && css`
        position: absolute;
        top: 80px;
        left: 30px;
        box-shadow: 0 1px 1px #888888;
      `
  }
`
export const ArrowLeftIcon = styled.img` 
  height: 18px;
  width: 18px;
`