/**
 * BackProduct type definition
 * @typedef {Object} BackProduct
 * @property {string} idProduct
 * @property {string} comment
 * @property {number} purchase
 * @property {string} name
 * @property {string} unit
 * @property {string} descriptionProduct
 * @property {number} price
 * @property {number} quantity
 * @property {string} _id
 * @property {array} optionsGroup
 */

/**
 * Function
 * @param {FrontProduct} product
 * @return {BackProduct} 
 */
export const productFrontToBack = (product) => {
    return {
        idProduct: product.id,
        comment: product.comments,
        purchase: product.purchase,
        name: product.name,
        unit: product.unit,
        descriptionProduct: product.description,
        price: product.price,
        quantity: product.quantity,
        _id: product.lineId,
        optionsGroup: product.optionsGroup,
    }
}

/**
 * Function
 * @param {BackProduct} product
 * @return {FrontProduct} 
 */
export const productBackToFront = (product) => {
    return {
        id: product.idProduct,
        comments: product.comment,
        purchase: product.purchase,
        unit: product.unit,
        name: product.name,
        description: product.descriptionProduct,
        price: product.price,
        quantity: product.quantity,
        lineId: product._id,
        optionsGroup: product.optionsGroup,
    }
}