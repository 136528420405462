import propTypes from 'prop-types';
import React from 'react';
import {
    ProductPrice,
    RadioButtonComponent, RadioButtonContainer, RadioContent, RadioMark,
} from './style';
import formatNumber from '../../helpers/formatNumber';

export default function RadioButton(props) {
    const { value, onChange, type, label, name, price } = props;

    return <RadioButtonContainer>
        <RadioButtonComponent type={type} checked={value} value={value} onChange={onChange} name={name} />
        <RadioMark checked={value} />
        <RadioContent>
            <div>
                {label}
            </div>
            {
                price > 0 &&
                <ProductPrice>
                    {formatNumber(price)}
                </ProductPrice> 
            }
        </RadioContent>
    </RadioButtonContainer>;
}

RadioButton.propTypes = {
    value: propTypes.bool,
    onChange: propTypes.func,
    label: propTypes.string,
    name: propTypes.string,
}


