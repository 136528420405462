import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import propTypes from 'prop-types';
import formatNumber from '../../../helpers/formatNumber';
import ProductCounter from "../ProductCounter";
import Modal from "../../../components/Modal";
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { CartModalContainer,
        ScrollTable,
        CartModalButton, 
        CartModalBody, 
        InputWrapper,
        InputContainer,
        ProductStock,
        Tag,
        TagWrapper } from './style';
import { IoMdClose } from 'react-icons/io';
import Table from 'react-bootstrap/Table';

function calculateFinalValue(editingValue, selectedOption, weightPerPiece) {
    let finalValue;
    if (selectedOption === 'gramos') {
        finalValue = editingValue;
    } else if (selectedOption === 'pieza') {
        if(weightPerPiece) {
            finalValue = weightPerPiece.$numberDecimal * editingValue;
        } else {
            finalValue = editingValue;
        }
    } else {
        finalValue = editingValue * 1000;
    }
    return finalValue;
}
export default function ProductModal(props) {

    const styleTd = {
        color: "black",
        height: "30px",
        border: "1px solid rgba(255,255,255,0.3)",
        overflowX: "auto",
        marginTop: "0px",
        padding: "18px",
        textAlign: "left",
        verticalAlign: "middle",
        fontWeight: "bold",
        fontSize: "15px",
        borderBottom: "solid 1px rgba(255,255,255,0.1)"
    }
    const styleTable = {
        width: "100%",
        tableLayout: "fixed",
    }
    const styleHeader = {
        backgroundColor: "rgba(255,255,255,0.3)"
    }

    const { t } = useTranslation();
    const { show,
            onClose,
            handleChangeItem,
            wholesalePrice,
            stock,
            inputValue,
            setInputValue,
            selectedOption,
            setSelectedOption,
            weightPerPiece } = props;
    const [editingValue, setEditingValue] = useState(inputValue);
    const convertToOriginalUnit = useCallback((valueInGrams, selectedOption) => {
        let originalValue;
        if (selectedOption === 'gramos') {
            originalValue = valueInGrams;
        } else if (selectedOption === 'pieza') {
            if(weightPerPiece) {
                originalValue = valueInGrams / weightPerPiece.$numberDecimal;
            } else {
                originalValue =  valueInGrams / 1000;
            }
        } else {
            originalValue = valueInGrams / 1000;
        }
        originalValue = Math.round((originalValue + Number.EPSILON) * 100) / 100;
    
        return originalValue;
    }, [weightPerPiece]);

    const plus = () => {
        let finalValue = calculateFinalValue(editingValue, selectedOption, weightPerPiece);
        handleChangeItem(parseFloat(finalValue));
        setInputValue(finalValue);
        onClose();
    };
    const disabledNumber = () => {
        return (
            !calculateFinalValue(editingValue, selectedOption, weightPerPiece)
            || calculateFinalValue(editingValue, selectedOption, weightPerPiece) > stock
            || calculateFinalValue(editingValue, selectedOption, weightPerPiece) <= -1
        );
    }
    const increment = () => {
        setEditingValue(prevValue => parseFloat(prevValue > 0 ? prevValue + 50 : 50));
    }
    
    const decrement = () => {
        setEditingValue(prevValue => parseFloat(prevValue - 50 > 0 ? prevValue - 50 : 0));
    }
    const handleOptionChange = (option) => {
        if (option === 'gramos') {
            setEditingValue(0);
            setInputValue(0);
        }
        setSelectedOption(option);
    };    
    const options = [
        { value: 'pieza', label: t('products.piece') },
        { value: 'gramos', label: t('products.grams') },
    ];
    const handleInputChange = (event) => {
        let value = event.target.value;
        let numberValue = parseFloat(value);
        numberValue = Math.round((numberValue + Number.EPSILON) * 100) / 100;
    
        setEditingValue(numberValue);
    }
    useEffect(() => {
        setEditingValue(convertToOriginalUnit(inputValue, selectedOption));
    }, [show, inputValue, selectedOption, convertToOriginalUnit]);

    return <Modal show={show}>
        <CartModalContainer>
            <CartModalButton onClick={onClose}><IoMdClose /></CartModalButton>
            <CartModalBody>
                {wholesalePrice && wholesalePrice.length > 0 && (
                    <ScrollTable>
                        <Table style={styleTable}>
                            <thead>
                                <tr>
                                <th  style={styleHeader}>{t("products.quantity")}</th>
                                <th  style={styleHeader}>{t("products.unit-price")}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {wholesalePrice?.map(({ _id, minimumAmount, maxAmount, price }) =>
                                <tr key={_id}>
                                <td style={styleTd}>{minimumAmount} - {maxAmount}</td>
                                <td style={styleTd}>{formatNumber(price)}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </ScrollTable>
                )}
                {weightPerPiece 
                ? (
                    <>
                        <InputWrapper>
                            <TagWrapper>
                                {options.map((option) => (
                                    <Tag
                                        key={option.value}
                                        selected={selectedOption === option.value}
                                        onClick={() => handleOptionChange(option.value)}
                                    >
                                        {option.label}
                                    </Tag>
                                ))}
                            </TagWrapper>
                        </InputWrapper>

                        {selectedOption === 'gramos' && (
                            <ProductCounter
                                value={editingValue}
                                onAdd={increment}
                                onDelete={decrement}
                                stock={stock}
                            />
                        )}

                        {selectedOption === 'pieza' && (
                            <InputContainer>
                                <Input
                                    type="number"
                                    onChange={handleInputChange}
                                    label={t('products.enter-the-amount')}
                                    autoComplete="off"
                                    name="editingValue"
                                    value={editingValue}
                                />
                            </InputContainer>
                        )}
                    </>
                ) 
                : null
            }

            {!weightPerPiece && (
                <ProductCounter
                    value={editingValue}
                    onAdd={increment}
                    onDelete={decrement}
                    stock={stock}
                />
            )}

                <ProductStock>{t("products.existence", { stock: stock - calculateFinalValue(editingValue, selectedOption, weightPerPiece).toFixed(2) })}</ProductStock>
                <Button
                    title={t("products.add")}
                    onClick={() => plus() }
                    disabled={disabledNumber()}
                />
            </CartModalBody>
        </CartModalContainer>
    </Modal>
}

ProductModal.propTypes = {
    inputValue: propTypes.oneOfType([
        propTypes.string,
        propTypes.number
    ]),
    show: propTypes.bool,
    onClose: propTypes.func,
    onClick: propTypes.func,
    handleChangeItem: propTypes.func,
    setInputValue: propTypes.func,
    initialState: propTypes.object,
    selectedOption: propTypes.string,
    setSelectedOption: propTypes.func,
}