import React, { useEffect, useState }  from 'react';
import propTypes from 'prop-types';
import { AlertBody, AlertComponent, AlertContainer, AlertMain, AlertText, AlertTitle, ChannelList, DoneImage} from '../style';
import done from '../../../../assets/icons/done.svg';
import { Channel } from '../../../../components/Channel';
import { useParams } from 'react-router';
import { getChannel } from '../../../../services/getChannel';
import { useCart } from '../../../../context/cart';

export const EditAlert = ({ show, message, messageConekta }) => {
    const { storeId } = useParams();
    const { cart } = useCart();
    const [channels, setChannels] = useState([]);
    useEffect(() => {
        if (cart.clientId === 'qrcode') {
            getChannel(storeId)
                .then(setChannels);
        }
    }, [storeId, cart]);
    return <AlertComponent show={show} >
        <AlertMain>
            <AlertContainer>
                <AlertBody>
                    <DoneImage src={done}></DoneImage>
                    <AlertTitle>
                        {message}
                    </AlertTitle>
                    <AlertText>
                        {messageConekta}
                    </AlertText>
                    <ChannelList>
                        {channels.length > 0 &&
                            channels.map(channel => <Channel
                                key={channel._id}
                                type={channel.channelType.key}
                                name={channel.name}
                                extras={channel.extras}
                            />)
                        }
                    </ChannelList>
                </AlertBody>
            </AlertContainer>
        </AlertMain>
    </AlertComponent>
}

EditAlert.propTypes = {
    show: propTypes.bool,
    message: propTypes.string,
    messageConekta: propTypes.string,
}



