import axios from "axios";

export const getAllProducts = async (categoryId, begin, end) => {
  const url = `${process.env.REACT_APP_TEST_URL}/marketplace/categories/get-all-products?id=${categoryId}&begin=${begin}&end=${end}`;

  const { data } = await axios.get(url);

  return data.response.map(({ salePrice, ...product }) => ({
    ...product,
    salePrice,
  }));
};
