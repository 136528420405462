import React from 'react'
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ProductPriceContainer, ProductDescription, ProductDetailSimpleContainer, ProductName, ProductPrice, ProductDetailComments } from '../../style';
import Textarea from '../../../../components/Textarea';
import formatNumber from '../../../../helpers/formatNumber';


export default function ProductDetailSimple({ name, description, price, comments, onComment, inputValue }) {
    const { t } = useTranslation();
    let actualPrice;
    
    if (typeof price === 'object' && price !== null && 'price' in price) {
        actualPrice = price.price;
    } else {
        actualPrice = price;
    }

    return (
        <ProductDetailSimpleContainer>
            <ProductName>
                {name}
            </ProductName>
            <ProductDescription>
                {description}
            </ProductDescription>
            <ProductDetailComments>
                <Textarea label={t('detail.comments')} value={comments} onChange={onComment} />
            </ProductDetailComments>
            <ProductPriceContainer>
                <ProductPrice>
                    {formatNumber(inputValue * actualPrice)}
                </ProductPrice>
            </ProductPriceContainer>
        </ProductDetailSimpleContainer>
    )
}

ProductDetailSimple.propTypes = {
    image: propTypes.string,
    name: propTypes.string,
    description: propTypes.string,
    price: propTypes.number,
}