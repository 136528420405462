import axios from 'axios';

export const payOrder = async (params) => {
    const msgUrl = `${process.env.REACT_APP_TEST_URL}/marketplace/sale/pay`;
    const messageBody = { type: 'text', ...params };
    const { data } = await axios.post(msgUrl, messageBody);
    if (data === null) {
        return Promise.resolve(null);
    }
    return Promise.resolve(data.channelClient);
};