import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { SubcategoriesList } from "../SubcategoriesList";
import findCategoryById from "../../../helpers/categories/findCategoryById";
import { useShop } from "../../../context/shop";

export const SubcategoryPage = () => {
  const { categoryId } = useParams();
  const [subcategories, setSubcategories] = useState([]);
  const { categories } = useShop();

  const getSubcategories = () => {
    const category = findCategoryById(categories, categoryId);

    if (category) {
      setSubcategories(category.childrens);
    }
  };

  useEffect(() => {
    if (categories.length > 0) {
      getSubcategories();
    }
  });

  return (
      <SubcategoriesList
        subcategories={subcategories}
        categoryId={categoryId}
      />
  );
};
