import React from 'react';
import { DeliveryMethods, DeliveryMethodItem, DeliveryOptionsContainer, DeliveryIcon, DeliveryMethodsContainer } from './style';
import { BiPackage } from 'react-icons/bi';
import { useTranslation } from "react-i18next";
import OrderAddress from './OrderAddress';
import { useShop } from '../../../context/shop';

export default function DeliveryOptions() {
    const { t } = useTranslation();
    const { deliveryOption } = useShop();
    const [delivery, setDelivery] = deliveryOption;
    const { deliveryEnabled } = useShop();

    return <DeliveryOptionsContainer>
        <DeliveryMethodsContainer>
            <DeliveryMethods onlyPickUp={!deliveryEnabled}>
                {
                    !deliveryEnabled ?
                        <DeliveryMethodItem>
                            <DeliveryIcon>
                                <BiPackage />
                            </DeliveryIcon>
                            {t('cart.pickUp')}
                        </DeliveryMethodItem>
                        : <>
                            <DeliveryMethodItem
                                selected={!delivery}
                                onClick={() => {
                                    setDelivery(false);
                                }}
                            >
                                <DeliveryIcon
                                >
                                    <BiPackage />
                                </DeliveryIcon>
                                {t('cart.pickUp')}
                            </DeliveryMethodItem>
                        </>
                }
            </DeliveryMethods>
        </DeliveryMethodsContainer>
        <OrderAddress delivery={delivery} deliveryEnabled={deliveryEnabled} />
    </DeliveryOptionsContainer>;
}

