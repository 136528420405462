import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getProduct } from "../../services/products/getProduct";
import { useCart } from "../../context/cart";
import ProductDetailHeader from "../ProductDetail/ProductDetailHeader";
import { ProductDetailPage } from "../ProductDetail/ProductDetailPage";
import { ProductDetailComponent } from "../ProductDetail/style";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useRouteNavigation } from "../../hooks/useRouteNavigation";
import { EditAlert } from '../ProductDetail/AddOrEditAlert/Edit';

export default function ProductEdit() {
  const { productLineId } = useParams();
  const { cart, editProduct } = useCart();
  const { t } = useTranslation();
  const [product, setProduct] = useState({});
  const [enableButton, setEnableButton] = useState(true);
  const goToRoute = useRouteNavigation();
  const [show, setShow] = useState(false);

  const findProductByLineId = (productLineId) => {
    const product = cart.products.find(
      (product) => product.lineId === productLineId
    );

    return product;
  };
  const productId = findProductByLineId(productLineId)?.id;

  useEffect(() => {
    getProduct(productId).then(setProduct);
  }, [productId]);

  const cartQuantity = cart.products.map((product) => {
    return {
      id: product.id,
      quantity: product.quantity,
    };
  });
  const cartProduct = cartQuantity.filter((e) => productId === e.id);
  let totalQ = cartProduct.reduce((total, { quantity }) => total + quantity, 0);

  let totalQuantityEdit = product.stock - totalQ;
  let wholesalePriceStatusEdit = product?.wholesalePriceStatus;
  const notifyProductEdited = (name) =>
    toast.success(t("detail.productEdited", { product: name }));
  const notifyError = () => toast.error(t('detail.productEditedError'));
  const editCartProduct = (groups, comments, quantity, tableWhoSalePrice) => {
    const editedProduct = findProductByLineId(productLineId);

    editProduct({
      lineId: editedProduct?.lineId,
      id: editedProduct?.id,
      name: editedProduct?.name,
      unit: 'gr',
      description: editedProduct?.description,
      price: tableWhoSalePrice ? tableWhoSalePrice.price : editedProduct?.price,
      purchase: editedProduct?.purchase,
      quantity: quantity,
      optionsGroup: groups,
      comments: comments,
    }).then(() => {
      setEnableButton(false);
      goToRoute('/home');
      goToRoute('/cart');

      setTimeout(function () {
        notifyProductEdited(editedProduct?.name);
      }, 250);
    }).catch(error => {
      console.log(error)
      notifyError();
      setShow(true);
    });
  };

  return (
    <ProductDetailComponent>
      {show === false ?
        <>
          <ProductDetailHeader product={product} />
          {Object.keys(product).length > 0 && (
            <ProductDetailPage
              images={product.imageUrl}
              name={product.name}
              description={product.descriptionProduct}
              price={product.salePrice}
              weightPerPiece={product.weightPerPiece}
              active={product.active}
              optionsGroup={product.optionsGroup}
              enableButton={enableButton}
              stock={product.stock}
              wholesalePriceStatus={product.wholesalePriceStatus}
              wholesalePrice={product.wholesalePrice}
              totalQuantityEdit={totalQuantityEdit}
              wholesalePriceStatusEdit={wholesalePriceStatusEdit}
              buttonTitle={t("detail.editProduct")}
              onClick={editCartProduct}
              initialState={{
                selectedOptions: findProductByLineId(productLineId)?.optionsGroup,
                initComments: findProductByLineId(productLineId)?.comments,
                initQuantity: findProductByLineId(productLineId)?.quantity,
                initPrice: findProductByLineId(productLineId)?.price,
              }}
            />
          )}
        </>
        :
        <EditAlert show={show} message={t('detail.productAddedError')} />
      }
    </ProductDetailComponent>
  );
}
