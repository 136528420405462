import React, { useState, useEffect } from "react";
import { ProductsPageBody } from "../style";
import { getProductsByCategory } from "../../../services/products/getProductsByCategory";
import { useParams } from "react-router";
import { ProductsByCategoryList } from "../ProductsList";
import EmptyProducts from "../EmptyProducts";
import Loader from "../../../components/Loader";
import InfiniteScroll from "react-infinite-scroller";
import { useImages } from "../../../context/images";

export const ProductsPage = () => {
  const { contextImageGallery } = useImages();
  const [, setImages] = contextImageGallery;
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    getProductsByCategory(1, 25, categoryId).then((products) => {
      setProducts(products);
      setLoading(false);
      setImages({});

      if (products.length === 25) {
        setHasMore(true);
      }
    });
  }, [categoryId, setImages]);

  const fetchMoreProducts = () => {
    getProductsByCategory(
      products.length + 1,
      products.length + 25,
      categoryId
    ).then((result) => {
      setProducts(products.concat(result));
      if (result.length <= 25) {
        setHasMore(false);
      }
    });
  };

  return (
    <ProductsPageBody>
      {loading ? (
        <Loader />
      ) : products.length > 0 ? (
        <InfiniteScroll
          pageStart={1}
          loadMore={fetchMoreProducts}
          hasMore={hasMore}
          loader={<Loader />}
        >
          <ProductsByCategoryList products={products} categoryId={categoryId} />
        </InfiniteScroll>
      ) : (
        <EmptyProducts />
      )}
    </ProductsPageBody>
  );
};
