import React, { useEffect, useState } from "react";
import { ProductDetailBody, ProductDetailContainer } from "../style";
import { ToastContainer } from "react-toastify";
import propTypes from "prop-types";
import ProductDetailOptions from "./ProductDetailOptions";
import ProductDetailSimple from "./ProductDetailSimple";
import ProductDetailFooter from "../ProductDetailFooter";

export const ProductDetailPage = (props) => {
  const {
    name,
    description,
    price,
    optionsGroup,
    enableButton,
    buttonTitle,
    onClick,
    initialState,
    stock,
    totalQuantityEdit,
    wholesalePriceStatusEdit,
    wholesalePriceStatus,
    wholesalePrice,
    weightPerPiece,
  } = props;
  const [selectedOption, setSelectedOption] = useState(!weightPerPiece ? 'gramos' : 'pieza');
  const [originalPrice, setOriginalPrice] = useState(price);
  const [tableWhoSalePrice, setTableWhoSalePrice] = useState(null);
  const requiredOptionsGroup = optionsGroup.filter((group) => group.required);
  const [quantity, setQuantity] = useState(initialState?.initQuantity || 100);
  const [inputValue, setInputValue] = useState(initialState?.initQuantity || 100);
  const [comments, setComments] = useState(initialState?.initComments || "");
  const [groups, setGroups] = useState(
    initialState?.selectedOptions ||
      requiredOptionsGroup?.map((optionsGroup) => {
        return {
          id: optionsGroup._id,
          name: optionsGroup.name,
          required: optionsGroup.required,
          options: [],
        };
      })
  );
  /** SELECT OPTIONS */
  const selectOption = (groupId, groupName, isRequired, selectedOption) => {
    setGroups((prevOptions) => {
      const result = [...prevOptions];

      const index = result.findIndex((group) => group.id === groupId);

      if (index === -1) {
        result[result.length] = {
          id: groupId,
          name: groupName,
          required: isRequired,
          options: [
            {
              id: selectedOption._id,
              name: selectedOption.name,
              salePrice: selectedOption.salePrice,
            },
          ],
        };
      } else {
        if (selectedOption._id === result[index].options[0]?.id && !result[index].required) {
          result.splice(index, 1);
        } else {
          result[index] = {
            ...result[index],
            options: [
              {
                id: selectedOption._id,
                name: selectedOption.name,
                required: selectedOption.required,
                salePrice: selectedOption.salePrice,
              },
            ],
          };
        }
      }

      return result;
    });
  };
  /** TOGGLE OPTIONS */
  const toggleOption = (groupId, groupName, isRequired, selectedOption) => {
    setGroups((prevOptions) => {
      const result = [...prevOptions];

      const index = result.findIndex((group) => group.id === groupId);

      if (index === -1) {
        result[result.length] = {
          id: groupId,
          name: groupName,
          required: isRequired,
          options: [
            {
              id: selectedOption._id,
              name: selectedOption.name,
              salePrice: selectedOption.salePrice,
            },
          ],
        };
      } else {
        const optionIndex = result[index].options.findIndex(
          (option) => option.id === selectedOption._id
        );

        if (optionIndex === -1) {
          result[index] = {
            ...result[index],
            options: [
              ...result[index].options,
              {
                id: selectedOption._id,
                name: selectedOption.name,
                required: selectedOption.required,
                salePrice: selectedOption.salePrice,
              },
            ],
          };
        } else {
          result[index] = {
            ...result[index],
            options: result[index].options.filter(
              (option) => option.id !== selectedOption._id
            ),
          };

          if (result[index].options.length === 0 && !result[index].required) {
            result.splice(index, 1);
          }
        }
      }

      return result;
    });
  };

  const isDisabled = () => {
    // checks if there are required groups and if those groups have options
    const requiredGroups = groups.filter((group) => group.required);
    const requiredOptions = groups.filter(
      (group) => group.required && group.options.length > 0
    );

    return (
      requiredGroups.length > 0 &&
      requiredOptions.length !== requiredGroups.length
    ) ? true : false;
  };

  const handleChangeItem = (value) => {
    setInputValue(value);
    setQuantity(value);
  }
  useEffect(() => {
    if (selectedOption === 'gramos') {
      setSelectedOption('gramos');
    } else {
      setSelectedOption('pieza');
    }
    const newTableWhoSalePrice = wholesalePrice?.find(
      ({ minimumAmount, maxAmount }) =>
        inputValue >= minimumAmount && inputValue <= maxAmount
    );
  
    setTableWhoSalePrice(newTableWhoSalePrice);
      if (newTableWhoSalePrice) {
        setOriginalPrice(newTableWhoSalePrice);
      } else {
        setOriginalPrice(price);
      }
  }, [wholesalePrice, inputValue, price, selectedOption, weightPerPiece]);
  return (
    <ProductDetailContainer>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        type="info"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ProductDetailBody>
        {optionsGroup.length > 0 ? (
          <ProductDetailOptions
            name={name}
            description={description}
            optionsGroup={optionsGroup}
            groups={groups}
            comments={comments}
            price={originalPrice}
            inputValue={inputValue}
            toggleOption={toggleOption}
            selectOption={selectOption}
            onComment={(event) => setComments(event.target.value)}
          />
        ) : (
          <ProductDetailSimple
            name={name}
            description={description}
            comments={comments}
            price={originalPrice}
            inputValue={inputValue}
            onComment={(event) => setComments(event.target.value)}
          />
        )}
      </ProductDetailBody>
      <ProductDetailFooter
        wholesalePriceStatus={wholesalePriceStatus}
        wholesalePrice={wholesalePrice}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        weightPerPiece={weightPerPiece}
        stock={stock}
        quantity={quantity}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleChangeItem={() => handleChangeItem(inputValue)}
        buttonTitle={buttonTitle}
        totalQuantityEdit={totalQuantityEdit}
        wholesalePriceStatusEdit={wholesalePriceStatusEdit}
        price={originalPrice}
        disabledButton={
          !enableButton || quantity > stock || isDisabled()
        }
        onClick={() => {
          onClick(groups, comments, inputValue, tableWhoSalePrice, originalPrice, selectedOption);
        }}
      />
    </ProductDetailContainer>
  );
};

ProductDetailPage.propTypes = {
  name: propTypes.string,
  description: propTypes.string,
  price: propTypes.number,
  wholesalePriceStatus: propTypes.bool,
  wholesalePrice: propTypes.array,
  weightPerPiece: propTypes.object,
  optionsGroup: propTypes.array,
  stock: propTypes.number,
  enableButton: propTypes.bool,
  buttonTitle: propTypes.string,
  onClick: propTypes.func,
  initialState: propTypes.object,
  images: propTypes.array,
  isEditing: propTypes.string,
};
