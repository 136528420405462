import styled, { css } from "styled-components";

export const ModalComponent = styled.div` 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: rgba(2, 30, 81, 0.4);
    display: none;
    z-index: 9999;
    
    ${({ show }) => show && css`
         display: block;
      `
    }
`

export const ModalMain = styled.div` 
    position: fixed;
    opacity: 1;
    top: ${(props) => props.top};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    left: 50%;
    transform: translate(-50%,-50%);  
`