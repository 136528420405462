import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import propTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { getChannel } from '../../../services/getChannel';
import Modal from "../../../components/Modal";
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { codes } from '../../../helpers/phoneCodes';
import Button from '../../../components/Button';
import { payOrder } from '../../../services/payments/payOrder';
import { CartModalContainer, ProductInfo, ProductOptions, CartModalButton, CartModalBody } from './style';
import { ErrorMessage, InputGroup, InputWrapper, InputWrapperShort, InputWrapperWide } from '../../PaymentInfo/style';
import { IoMdClose } from 'react-icons/io';
import { useRouteNavigation } from "../../../hooks/useRouteNavigation";
import { useParams } from 'react-router';
import { useCart } from '../../../context/cart';
import { Thanks } from '../../Cart/Thanks/index';

export default function ChannelModal(props) {
    const { register, formState, control, handleSubmit, reset, watch } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        criteriaMode: "all",
        shouldFocusError: true,
    });
    const [showThankYouModal, setShowThankYouModal] = useState(false);
    const [, setChannels] = useState([]);
    const { errors, isValid } = formState;
    const { storeId } = useParams();
    const { cart } = useCart();
    const { t } = useTranslation();
    const { show, onClose } = props;
    const checkboxValue = watch('checkbox');
    const goToRoute = useRouteNavigation();
    useEffect(() => {
        if (cart.clientId === 'qrcode') {
            getChannel(storeId)
                .then(setChannels);
        }
    }, [storeId, cart]);

    const onSubmit = async (data) => {

        let params = {
            channelId: `${process.env.REACT_APP_CHANNEL_ID}`, 
            idSale: cart.id,
            paymentMethod: 'cash',
            clientMarketplace: {
                name: data.name,
                phone: `+${data.code.value}${data.phone}`,
            }
        }
        try {
            const result = await payOrder(params);
            if (result === null) {
                setShowThankYouModal(true);
                setTimeout(() => {
                    setShowThankYouModal(false);
                    goToRoute('/home');
                    window.location.reload();
                    reset();
                }, 5000);
            }
        } catch (error) {
            console.error(error);
        }
    }
    return <Modal show={show}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <CartModalContainer>
            <CartModalButton onClick={onClose}><IoMdClose /></CartModalButton>
            <CartModalBody>
                <ProductInfo>
                    {t('cart.channel')}
                </ProductInfo>
                <ProductOptions>
                <InputWrapper>
                        <Input
                            label={t('cart.name')}
                            name="name"
                            autoComplete="off"
                            error={errors.name}
                            {...register("name", { required: true })}
                        />
                        {errors.name && <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>}
                    </InputWrapper>

                    <InputGroup>
                        <InputWrapperShort>
                            <Controller
                                control={control}
                                name="code"
                                rules={{ required: true }}
                                defaultValue={{ value: '52', label: 'MX' }} 
                                error={errors.code}
                                render={({ field }) => {
                                    return <Select
                                        label={t('payment.code')}
                                        options={codes}
                                        {...field}
                                    />
                                }}
                            />
                            {errors.code && <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>}

                        </InputWrapperShort>
                        <InputWrapperWide>
                            <Input
                                label={t('payment.phone')}
                                name="phone"
                                maxLength={10}
                                error={errors.phone}
                                {...register("phone", {
                                    required: true,
                                    minLength: 10,
                                    maxLength: 10,
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: t('validations.invalidPhone'),
                                    },
                                })}                                
                            />
                            {errors.phone && errors.phone?.type === "required" && <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>}
                            {errors.phone && errors.phone?.type === "pattern" && <ErrorMessage>{errors.phone.message}</ErrorMessage>}
                        </InputWrapperWide>
                    </InputGroup>
                        <InputWrapperWide>
                            <div>
                            <label>
                                <input type="checkbox" {...register("checkbox", { required: true })} />
                                {t('payment.wa')}
                            </label>
                            {errors.checkbox && <ErrorMessage>{t('validations.requiredField')}</ErrorMessage>}
                            </div>
                        </InputWrapperWide>
                </ProductOptions>
                    <Thanks show={showThankYouModal} message={t('payment.thanks')} />
                <Button
                    title={t("cart.order")}
                    disabled={!isValid || !checkboxValue}
                />
            </CartModalBody>
        </CartModalContainer>
        </form>
    </Modal>
}

ChannelModal.propTypes = {
    show: propTypes.bool,
    onClose: propTypes.func,
}